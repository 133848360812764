.store-wrapper {
    width: 100%;
    height: 100vh;
    margin: 0 auto;
}
.store-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: linear-gradient(to bottom, #161d2d, #161d2d 30rem, #2a3547 30rem, #2a3547);
    position: relative;
    padding: 7rem 3rem 4rem;
}

.store-top {
    width: 100%;
    max-width: 60rem;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #e7e7e3;
}
.store-top-left {
    width: 60%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.store-top-right {
    width: 40%;
    height: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.store-top-title {
    font-size: 3.5rem;
    font-weight: bold;
    white-space: nowrap;
}
.store-top-info {
    width: 75%;
    font-size: 1.25rem;
    line-height: 1.5rem;
}
.store-top-btn,
.store-top-btn:is(:hover,:focus) {
    width: 10rem;
    height: 2.5rem;
    border-radius: 2.5rem;
    background-color: #e7e7e3;
    color: #000;
    border: none;
    padding: .5rem 1rem;
    margin: 1rem 1rem 0 0;
}
.store-top-btn:active {
    background-color: #bbb;
}

.store-top-img {
    margin-top: 2.25rem;
    width: 26rem;
    height: 18rem;
    animation: storePulse 2s linear infinite;
}
.store-top-img img {
    width: 100%;
    height: auto;
}

.store-middle {
    width: calc(100% + 6rem);
    min-height: 7rem;
    margin: 3rem 0rem 2rem;
    transform: scaleY(-1);
    background-repeat: repeat-x;
    background-size: 14rem 7rem;
    background-position: top;
    opacity: .3;
}

.store-bottom {
    height: fit-content;
    width: 100%;
    max-width: 62rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 1rem;
}

.store-bottom-stickers {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
}
.store-bottom-sticker {
    width: 18rem;
    height: fit-content;
    color: #e7e7e3;
    margin: 1rem;
    cursor: pointer;
}
.store-bottom-sticker img {
    width: 100%;
    height: auto;
    pointer-events: none;
}
.store-bottom-info {
    margin: .5rem 0;
    pointer-events: none;
}

@keyframes storePulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(.95);
    }
    100% {
        transform: scale(1);
    }
}

@media screen and (max-width: 1024px) {
    .store-top-title {
        font-size: 3rem;
    }
}
@media screen and (max-width: 900px) {
    .store-container {
        padding: 7rem 2rem 4rem;
    }
    .store-top-left {
        width: 55%;
    }
    .store-top-right {
        width: 45%;
    }
    .store-top-title {
        font-size: 2.5rem;
    }
    .store-top-info {
        font-size: 1.1rem;
    }
    .store-top-btn {
        width: 9rem;
    }
    .store-top-img {
        margin-top: .5rem;
        width: 23rem;
        height: 14rem;
    }
    .store-middle {
        width: calc(100% + 4rem);
    }
}
@media screen and (max-width: 768px) {
    .store-container {
        padding: 7rem 3rem 4rem;
    }
    .store-top-title {
        font-size: 1.9rem;
    }
    .store-top-img {
        margin-top: 0;
        width: 16rem;
        height: 10rem;
    }
    .store-top-btn {
        width: 8rem;
        height: 2rem;
        border-radius: 2rem;
        padding: .25rem;
    }
    .store-middle {
        width: calc(100% + 6rem);
    }
}
@media screen and (max-width: 600px) {
    .store-top-title {
        font-size: 1.5rem;
    }
    .store-top-info {
        font-size: 1rem;
    }
    .store-top-img {
        width: 14rem;
        height: 10rem;
    }
}
@media screen and (max-width: 480px) {
    .store-container {
        padding: 6rem 2rem 4rem;
        background: linear-gradient(to bottom, #161d2d, #161d2d 28rem, #2a3547 28rem, #2a3547);
    }
    .store-top-left {
        width: 55%;
    }
    .store-top-right {
        width: 45%;
    }
    .store-top-title {
        font-size: 1.25rem;
    }
    .store-top-info {
        font-size: .9rem;
        line-height: 1.25rem;
    }
    .store-top-img {
        width: 11rem;
    }
    .store-middle {
        margin-top: 2rem;
        width: calc(100% + 4rem);
    }
}
.ate-container {
    width: 100%;
    height: 35rem;
    background-color: #161d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
}
.ate-container-inner {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
}

.ate-words {
    height: 100%;
    width: 35rem;
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #e7e7e3;
}
.ate-words p:first-of-type {
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 0;
    margin-left: .25rem;
    line-height: 4.5rem;
}
.ate-words p:nth-of-type(2) {
    font-size: 2rem;
    margin-left: .25rem;
}
.ate-words p:last-of-type {
    font-size: 1.25rem;
    margin-bottom: .5rem;
    margin-left: .25rem;
}
.ate-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
}
.ate-tags span {
    height: 2rem;
    width: fit-content;
    border: 1px solid #bbb;
    background-color: transparent;
    color: #e7e7e3;
    padding: .2rem .75rem;
    border-radius: 2rem;
    margin: .25rem
}
.ate-btn,
.ate-btn:is(:hover,:focus,:active) {
    width: 12rem;
    height: 3rem;
    border: none;
    border-radius: 3rem;
    background: linear-gradient(to right top, #2a3547, #607aa4) !important;
    color: #e7e7e3;
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: .75rem;
    margin-left: .25rem;
}

.ate-animate-container {
    height: 100%;
    max-height: 18rem;
    min-width: 20rem;
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

/* .ate-square::before {
    animation: shadow 1.6s ease-in-out infinite both;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    bottom: 2rem;
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.2);
    content: '';
    height: 0.1rem;
    left: 35%;
    position: absolute;
    right: 11%;
} */

/* .ate-down {
    animation: down 1.6s ease-in infinite both;
    bottom: 2rem;
    position: absolute;
}
  
.ate-down .ate-up {
    animation: up 1.6s ease-in-out infinite both;
}
  
.ate-down .ate-up .ate-squeeze {
    animation: squeeze 1.6s cubic-bezier(0.72, 0.03, 0.28, 0.97) infinite both;
    transform-origin: 50% 100%;
}
  
.ate-down .ate-up .ate-squeeze .ate-rotate-in {
    animation: rotate-in 1.6s ease-out infinite both;
}
  
.ate-down .ate-up .ate-squeeze .ate-rotate-in .ate-rotate-out {
    animation: rotate-out 1.6s ease-in infinite both;
} */
  
.ate-qr-code {
    background-color: transparent;
    height: 5rem;
    width: 5rem;
}

/* @keyframes down {
    0% {
      transform: translateY(-10rem);
    }
    20%,
    100% {
      transform: translateY(0);
    }
}
  
@keyframes up {
    0%,
    75% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-10rem);
    }
}
  
@keyframes squeeze {
    0%,
    4% {
      transform: scale(1);
    }
    45% {
      transform: scale(1.8, 0.4);
    }
    100% {
      transform: scale(1);
    }
}
  
@keyframes rotate-in {
    0% {
      transform: rotate(-135deg);
    }
    20%,
    100% {
      transform: rotate(0deg);
    }
}
  
@keyframes rotate-out {
    0%,
    80% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(135deg);
    }
}
  
@keyframes shadow {
    0%,
    100% {
      transform: scaleX(0.3);
    }
    45%,
    50% {
      transform: scaleX(0.8);
    }
} */


@media screen and (max-width: 850px) {
    .ate-words p:first-of-type {
        font-size: 3.5rem;
    }
    .ate-words p:nth-of-type(2) {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 768px) {
    .ate-container-inner {
        padding: 1rem .5rem;
    }
    .ate-words {
        width: 25rem;
    }
    .ate-words p:first-of-type {
        font-size: 3.5rem;
        line-height: 3rem;
        margin-bottom: .5rem;
    }
    .ate-words p:nth-of-type(2) {
        font-size: 1.5rem;
        margin-bottom: .5rem;
    }
    .ate-words p:last-of-type {
        font-size: 1.1rem;
        margin-bottom: .25rem;
    }
    .ate-tags {
        margin-bottom: .5rem;
    }
    .ate-tags span {
        height: 1.75rem;
        border-radius: 1.75rem;
        padding: 0.05rem .6rem;
    }
    .ate-animate-container {
        width: 14rem;
        min-width: 14rem;
    }
    .ate-animate-inner {
        height: 6rem;
    }
    .ate-animate-1,
    .ate-animate-2 {
        width: 9.5rem;
        border-radius: 6rem;
    }
    .ate-animate-1 {
        left: .75rem;
    }
    .ate-animate-2 {
        right: .75rem;
    }
    .ate-animate-circle {
        height: 4.75rem;
        width: 4.75rem;
    }
}
@media screen and (max-width: 550px) {
    .ate-container {
        height: 30rem;
    }
    .ate-words {
        margin-left: .5rem;
    }
    .ate-words p:first-of-type {
        font-size: 2.15rem;
        line-height: 2rem;
    }
    .ate-words p:nth-of-type(2) {
        font-size: 1.1rem;
        line-height: 1.3rem;
    }
    .ate-words p:last-of-type {
        font-size: .9rem;
        margin-bottom: 0;
    }
    .ate-tags {
        margin-bottom: .25rem;
    }
    .ate-tags span {
        height: 1.25rem;
        font-size: .75rem;
        padding: 0 .5rem;
    }
    .ate-btn,
    .ate-btn:is(:hover,:focus,:active) {
        height: 2.25rem;
        width: 9rem;
        font-size: .9rem;
        margin-top: .5rem;
    }
}
@media screen and (max-width: 480px) {
    .ate-container {
        height: 27rem;
    }
    .ate-words p:first-of-type {
        font-size: 1.75rem;
    }
    .ate-animate-container {
        width: 11rem;
        min-width: 11rem;
    }
    .ate-animate-inner {
        height: 4rem;
        margin: .25rem 0;
    }
    .ate-animate-1,
    .ate-animate-2 {
        width: 6rem;
        border-radius: 4rem;
    }
    .ate-animate-circle {
        height: 3rem;
        width: 3rem;
    }
    .ate-btn,
    .ate-btn:is(:hover,:focus,:active) {
        min-height: 2rem;
        height: 2.25rem;
        width: 9rem;
        font-size: .9rem;
        margin-top: .5rem;
    }
    .ate-square::before {
        left: 20%;
        right: 10%;
    }
    .ate-down {
        left: 4.5rem;
    }
    .ate-qr-code {
        height: 4rem;
        width: 4rem;
    }
    .ate-qr-code i {
        font-size: 4rem !important;
    }
    @keyframes up {
        0%,
        75% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-10rem)
        }
    }
}
.floating-container,
.floating-container:is(:hover,:focus,:active) {
    width: 8rem;
    height: 4rem;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: #e7e7e3;
    color: #161d2d;
    border: none;
    border-radius: 4rem;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #161d2d;
    text-decoration: none;
    font-size: 1.5rem;
    transform: scale(1);
    transition: transform .2s ease, background-color 2s ease, color 2s ease;
}
.floating-container:hover,
.floating-container.scrolled:hover {
    transform: scale(1.1);
    transition: transform .2s ease;
}
.floating-container.scrolled {
    background-color: rgba(0,0,0,.5);
    color: #e7e7e3;
    transition: background-color 2s ease, color 2s ease;
}

.floating-words {
    display: flex;
    font-size: .9rem;
}

.floating-container.hide {
    display: none;
}


@media screen and (max-width: 768px) {
    .floating-words {
        display: none;
    }
    .floating-container,
    .floating-container:is(:hover,:focus,:active) {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
    }
}
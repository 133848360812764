/* Container with the store and fade effect */
.storeContainer {
    width: 100vw;
    background-color: #2a3547;
    padding: 2rem 3rem 0rem;
    position: relative; /* Needed for pseudo-elements positioning */
    overflow: hidden; /* Prevent overflow caused by padding */
  }
  
  .ecwid-productBrowser {
    background-color: #f6f6f6 !important;
    border-radius: 2rem;
    padding: 1rem 2rem !important;
  }
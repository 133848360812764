.top-bar-container {
    width: fit-content;
    max-width: 100%;
    height: 6rem;
    padding: 1rem 0.5rem;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}
.top-bar {
    width: 60rem;
    height: 4rem;
    border: none;
    background-color: #e7e7e3;
    border-radius: 4rem;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    -webkit-transition: background-color 2s ease, box-shadow 2s ease;
    transition: background-color 2s ease, box-shadow 2s ease;
}
.top-bar.scrolled {
    background-color: rgba(0,0,0,.5);
    box-shadow: none;
    -webkit-transition: background-color 2s ease, box-shadow 2s ease;
    transition: background-color 2s ease, box-shadow 2s ease;
}
.top-bar-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 2rem;
    transition: color 2s ease;
}
.top-bar-logo.scrolled {
    color: #e7e7e3;
    transition: color 2s ease;
}
.top-bar-logo .far {
    margin-right: .5rem;
}
.top-bar-btns {
    margin: 0 0 0 auto;
}
.top-bar-btns button {
    margin-left: 1rem;
}
.top-bar-btns a {
    color: #000;
    text-decoration: none;
    transition: color 2s ease;
}
.top-bar-btns a:hover {
    text-decoration: underline;
    color: #999;
}
.top-bar-btns a.scrolled {
    color: #e7e7e3;
    transition: color 2s ease;
}
.top-bar-btns button,
.top-bar-btns button:is(:hover,:focus,:active) {
    border: none;
    border-radius: 2rem;
    width: 6rem;
    height: 2rem;
    color: #e7e7e3;
    background: linear-gradient(to right top, #161d2d, #607aa4) !important;
}
.top-bar-img {
    width: 4.75rem;
    height: auto;
    margin-right: .5rem;
}
.top-bar-desktop {
    display: flex;
}
@media screen and (max-width: 600px) {
    .top-bar-container {
        height: 5rem;
    }
    .top-bar {
        height: 3.5rem;
        padding: 0 1rem;
    }
    .top-bar-desktop {
        display: none;
    }
    .top-bar-logo {
        font-size: 1.5rem;
    }
    .top-bar-logo .far {
        margin-right: .25rem;
    }
    .top-bar-btns button {
        margin-left: .5rem;
    }
    .top-bar-img {
        width: 4.25rem;
    }
}
.footer-container {
    width: 100%;
    height: fit-content;
    background: linear-gradient(to bottom, #2a3547,#161d2d);
    border-top: 2px solid;
    border-image: linear-gradient(to right,#2a3547,#607aa4,#94add8,#607aa4,#2a3547) 1;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 4;
}
.footer-top {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1rem 2rem;
}
.footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #e7e7e3;
    color: #e7e7e3;
    width: calc(100% + 1rem);
    padding: 1rem;
    font-size: .9rem;
}

.footer-logo {
    width: 4.75rem;
    height: auto;
    margin-right: .5rem;
}

.footer-first {
    width: 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #e7e7e3;
}
.footer-second a,
.footer-third a {
    color: #666;
    text-decoration: none;
}
.footer-second a:hover,
.footer-third a:hover {
    color: #e7e7e3;
    text-decoration: underline;
}

.footer-second {
    width: 14rem;
    margin: 0 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #e7e7e3;
    font-size: .9rem;
    text-align: center;
}
.footer-title {
    font-size: 1.1rem;
    margin-bottom: .25rem;
    position: relative;
}
.footer-title-underline1 {
    position: absolute;
    width: 5.35rem;
    border-bottom: 2px solid #e7e7e3;
    top: 1.5rem;
    left: .1rem;
}
.footer-title-underline2 {
    position: absolute;
    width: 2.55rem;
    border-bottom: 2px solid #e7e7e3;
    top: 1.5rem;
    left: .1rem;
}

.footer-third {
    width: 14rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #e7e7e3;
    font-size: .9rem;
    text-align: center;
}
.footer-third-inner {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-third-column {
    width: 10rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.footer-third-column:first-of-type a {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1rem;
}
.footer-third-column:last-of-type a {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 1rem;
}

@media screen and (max-width: 768px) {
    .footer-top {
        flex-direction: column;
    }
    .footer-first {
        margin-bottom: 1rem;
    }
}
@media screen and (max-width: 480px) {
   
}
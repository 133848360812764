.scan-like-container {
    width: 100%;
    height: 8rem;
    /* background-color: #e9eaef; */
    padding: 1rem .5rem;
    background-color: #161d2d;
    display: flex;
    justify-content: center;
}
.scan-like {
    width: 100%;
    max-width: 60rem;
    height: 4rem;
    border: none;
    background: linear-gradient(to right, #607aa4, #2a3547) !important;
    border-radius: 4rem;
    display: flex;
    align-items: center;
    padding: 0;
}
.scan-like-words {
    color: #e7e7e3;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 11rem;
    border-right: 2px solid #161d2d;
}
.scan-like-companies-wrapper {
    height: 100%;
    width: calc(100% - 10rem);
    border-radius: 0 4rem 4rem 0 !important;
    overflow: hidden !important;
    display: flex;
    position: relative;
    z-index: 1;
}
.scan-like-companies {
    display: flex;
    align-items: center;
    justify-content: space-around;
    animation: slide 20s linear infinite;
}
.scan-like-company {
    display: flex;
    align-items: center;
    width: fit-content;
    margin-right: 2.5rem;
}
.scan-like-company i {
    font-size: 2rem;
    border-radius: 50%;
    margin-right: .5rem;
    color: #e7e7e3;
}
.scan-like-company span {
    font-size: 1.2rem;
    color: #e7e7e3;
}
@keyframes slide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

@media screen and (max-width: 480px) {
    .scan-like-words {
        width: 7rem;
        font-size: 1rem;
    }
    .scan-like-companies-wrapper {
        width: calc(100% - 7rem);
    }
    .scan-like-container {
        height: 6rem;
    }
}
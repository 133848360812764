.easily-container {
    width: 100%;
    height: 26rem;
    padding: 2rem 2rem 6rem 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #161d2d;
}
.easily-container-inner {
    width: 100%;
    max-width: 50rem;
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 .5rem;
}
.easily-emoji-parallax {
    width: 60%;
    max-width: 20rem;
    height: 20rem;
    border: 5px solid #607aa4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    position: relative;
}
.easily-emoji-parallax span {
    font-size: 3rem;
}
.easily-words {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 1rem;
}
.easily-words p:first-of-type {
    font-size: 5rem;
    color: #e7e7e3;
    font-weight: bold;
    margin-bottom: 0;
}
.easily-words p:last-of-type {
    font-size: 1.5rem;
    color: #e7e7e3;
    margin-bottom: 0;
}
.easily-icon {
    height: 3.5rem;
    width: 3.5rem;
    background-color: #161d2d;
    color: #e7e7e3;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 3rem;
}
.icon-1 {
    top: -1.75rem;
}
.icon-2 {
    right: -1.75rem;
}
.icon-3 {
    bottom: -1.75rem;
}
.icon-4 {
    left: -1.75rem;
}

@media screen and (max-width: 768px) {
    .easily-container {
        height: 22rem;
    }
    .easily-words p:first-of-type {
        font-size: 3.25rem;
    }
    .easily-words p:last-of-type {
        font-size: 1.25rem;
    }
    .easily-emoji-parallax {
        width: 13rem;
        height: 13rem;
    }
}
@media screen and (max-width: 480px) {
    .easily-container {
        height: 20rem;
    }
    .easily-words p:first-of-type {
        font-size: 1.75rem;
    }
    .easily-words p:last-of-type {
        font-size: 1rem;
    }
    .easily-emoji-parallax {
        width: 8rem;
        height: 8rem;
    }
    .easily-icon {
        height: 2.5rem;
        width: 2.5rem;
        font-size: 2rem;
    }
}
.scan-large-container {
    width: 100%;
    height: 46rem;
    background: linear-gradient(to bottom, #607aa4, #2a3547);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5rem;
}

.scan-large-container p:first-of-type {
    font-size: 6rem;
    font-weight: bold;
    color: #e7e7e3;
}
.scan-large-container p:last-of-type {
    margin-top: 2rem;
    font-size: 2.5rem;
    color: #e7e7e3;
    width: 100%;
    max-width: 45rem;
    text-align: center;
    line-height: 2.75rem;
    padding: 0 .5rem;
}

.scan-large-companies-wrapper {
    height: 16rem;
    width: 100%;
    overflow: hidden;
    display: flex;
    position: relative;
}
.scan-large-companies {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-around;
    animation: slide 20s linear infinite;
}
.scan-large-company {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 10rem;
    height: 10rem;
    background-repeat: none;
    background-size: 10rem 10rem;
    margin-right: 7rem;
}

.scan-large-company span {
    font-size: 2.25rem;
    color: #e7e7e3;
    white-space: nowrap;
    font-weight: bold;
}
@keyframes slide {
    0% {
        transform: translateX(0%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.scan-large-input-wrapper {
    width: 100%;
    max-width: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
    position: relative;
    margin-top: 1rem;
    padding: 0 .5rem;
}
.scan-large-input,
.scan-large-input:is(:hover,:focus,:active) {
    width: 100%;
    max-width: 50rem;
    height: 4rem;
    border: 2px solid #607aa4;
    border-radius: 4rem !important;
    background: rgba(0,0,0,.5);
    padding-top: .5rem;
    padding-left: 1.75rem;
    color: #e7e7e3;
    font-size: 1.5rem;
    outline: none;
}
.scan-large-container input:focus + .floating-label {
    top: .2rem;
    left: 2.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}
.scan-large-container .floating-label {
    position: absolute;
    pointer-events: none;
    left: 2rem;
    top: .55rem;
    font-size: 1.75rem;
    transition: 0.2s ease all;
    color: #e7e7e3;
    opacity: 0.8;
}
.scan-large-container .floating-label.filled {
    top: .2rem;
    left: 2.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.scan-large-btn {
    position: absolute;
    right: 1.25rem;
    color: #e7e7e3;
    height: 2.5rem;
    width: 6rem;
    border: none;
    border-radius: 2.5rem !important;
    background: linear-gradient(to right top, #2a3547, #607aa4) !important;
}

@media screen and (max-width: 768px) {
    .scan-large-container {
        height: 40rem;
        padding: 1rem 0 4rem 0;
    }
    .scan-large-container p:first-of-type {
        font-size: 4rem;
    }
    .scan-large-container p:last-of-type {
        font-size: 1.75rem;
        margin-bottom: .5rem;
    }
    .scan-large-companies-wrapper {
        height: 14rem;
    }
    .scan-large-input-wrapper {
        margin-top: .5rem
    }
    .scan-large-company {
        height: 10rem;
        width: 10rem;
    }
}
@media screen and (max-width: 480px) {
    .scan-large-container {
        height: 32rem;
    }
    .scan-large-container p:first-of-type {
        font-size: 3rem;
    }
    .scan-large-container p:last-of-type {
        font-size: 1.5rem;
        line-height: 1.75rem;
        margin-top: 1.5rem;
    }
    .scan-large-companies-wrapper {
        height: 10rem;
    }
    .scan-large-company {
        margin-right: 3.5rem;
        height: 7rem;
        width: 7rem;
        background-size: 7rem 7rem;
    }
    .scan-large-company span {
        font-size: 1.5rem;
    }
    .scan-large-input,
    .scan-large-input:is(:hover,:focus,:active) {
        height: 3.5rem;
        font-size: 1rem;
    }
    .scan-large-container .floating-label {
        top: .5rem;
        font-size: 1.5rem;
    }
    .scan-large-btn {
        height: 2rem;
    }
}
.builder-container { 
    width: 100%;
    height: fit-content;
    min-height: 10rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem 4rem;
    background-color: #2a3547;
    color: #fff;
}
.builder-container-title {
    font-size: 5rem;
    font-weight: bold;
}

.builder-container-inner {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 1rem;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.builder-container-inner img {
    pointer-events: none;
    filter: brightness(100%);
    transition: filter .2s ease;
}
.builder-container-inner:hover img {
    filter: brightness(50%);
    transition: filter .2s ease;
}

.builder-container-btn,
.builder-container-btn:is(:hover,:focus,:active) {
    width: 12rem;
    height: 3rem;
    border: none;
    border-radius: 3rem;
    background: linear-gradient(to right top, #2a3547, #607aa4);
    color: #e7e7e3;
    font-size: 1.25rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    opacity: 0;
    transition: opacity .2s ease;
}
.builder-container-inner:hover .builder-container-btn,
.builder-container-inner:hover .builder-container-btn:is(:hover,:focus,:active) {
    opacity: 1;
    transition: opacity .2s ease;
}

@media screen and (max-width: 1550px) {
    .builder-container-inner {
        width: 70%;
    }
}

@media screen and (max-width: 1350px) {
    .builder-container-inner {
        width: 80%;
    }
}

@media screen and (max-width: 1161px) {
    .builder-container-inner {
        width: 95%;
    }
}
@media screen and (max-width: 992px) {
    .builder-container-inner {
        width: 100%;
    }
}
@media screen and (max-width: 768px) {
    .builder-container-title {
        font-size: 4rem;
    }
    .builder-container-inner img {
        filter: brightness(50%);
    }
    .builder-container-btn,
    .builder-container-btn:is(:hover,:focus,:active) {
        opacity: 1;
        width: 10rem;
        height: 2.25rem;
        border-radius: 2.25rem;
        font-size: 1rem;
    }
}
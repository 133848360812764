.lanalytics-container {
    width: 100%;
    height: 30rem;
    background-color: #161d2d;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    z-index: 3;
}

.lanalytics-container-inner {
    width: 100%;
    height: 100%;
    max-width: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
}

.lanalytics-words {
    height: 100%;
    width: 60%;
    max-width: 26rem;
    margin-right: .75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #e7e7e3;
}
.lanalytics-words p:first-of-type {
    font-size: 6rem;
    font-weight: bold;
    margin-bottom: 0;
}
.lanalytics-words p:last-of-type {
    font-size: 2rem;
    margin-bottom: 0;
    line-height: 2rem;
}

.lanalytics-animate {
    height: 100%;
    width: 16.5rem;
    margin-left: .75rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.lanalytics-animate-inner {
    width: 100%;
    height: 3rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.animate1,
.animate2,
.animate3,
.animate4,
.animate5 {
    height: 3rem;
    width: 3rem;
    border-radius: 3rem;
    background: linear-gradient(to right top, #161d2d, #607aa4) !important;
    position: absolute;
}
.animate1 {
    left: 0rem;
    animation: move1 7s linear infinite;
}
.animate2 {
    left: 3.4rem;
    animation: move2 7s linear infinite;
}
.animate3 {
    animation: move3 7s linear infinite;
}
.animate4 {
    right: 3.4rem;
    animation: move4 7s linear infinite;
}
.animate5 {
    right: 0rem;
    animation: move5 7s linear infinite;
}

@keyframes move1 {
    0% {
        top: 0;
        height: 3rem;
    }
    2.5% {
        top: -3rem; 
    }
    5% {
        top: .5rem;
    }
    6.125% {
        top: 0;
    }
    7.25% {
        top: .25rem;
    }
    8.375% {
        top: 0rem;
    }
    15% {
        top: 0;
    }
    16% {
        top: 6.5rem;
    }
    17.125% {
        top: 6rem;
    }
    18.25% {
        top: 6.25rem;
    }
    19.375% {
        top: 6rem;
    }
    40% {
        top: 6rem;
    }
    42.5% {
        top: -.5rem;
    }
    43.625% {
        top: 0;
    }
    44.75% {
        top: -.25rem;
    }
    45.875% {
        top: 0;
    }
    53% {
        top: 0;
    }
    55.5% {
        top: -3rem;
    }
    58% {
        top: .5rem;
    }
    59.125% {
        top: 0;
    }
    60.25% {
        top: .25rem;
    }
    61.375% {
        top: 0;
    }
    68% {
        top: 0;
        height: 3rem;
    }
    69.5% {
        top: 3rem;
    }
    71% {
        top: -.5rem;
        height: 9rem
    }
    72.125% {
        top: 0;
    }
    73.25% {
        top: -.25rem;
    }
    73.375% {
        top: 0;
    }
    88% {
        top: 0;
        height: 9rem;
    }
    89% {
        top: -.5rem;
        height: 3rem;
    }
    90.125% {
        top: 0;
    }
    91.25% {
        top: -.25rem;
    }
    92.375% {
        top: 0;
    }
}
@keyframes move2 {
    0% {
        top: 0;
        height: 3rem;
    }
    4% {
        top: -3rem; 
    }
    6.5% {
        top: .5rem;
    }
    7.625% {
        top: 0;
    }
    8.75% {
        top: .25rem;
    }
    9.875% {
        top: 0;
    }
    15% {
        top: 0;
        height: 3rem;
    }
    17.5% {
        top: 4.5rem;
        height: 4.5rem;
    }
    18.625% {
        top: 4rem;
        height: 4.5rem;
    }
    19.75% {
        top: 4.25rem;
    }
    20.875% {
        top: 4.5rem;
    }
    40% {
        top: 4.5rem;
        height: 4.5rem
    }
    44% {
        top: -.5rem;
    }
    45.125% {
        top: 0;
        height: 3rem;
    }
    46.25% {
        top: -.25rem;
    }
    47.375% {
        top: 0;
    }
    53%{
        top: 0;
    }
    57% {
        top: -3rem;
    }
    59.5% {
        top: .5rem;
    }
    60.625% {
        top: 0;
    }
    61.75% {
        top: .25rem;
    }
    62.875% {
        top: 0;
    }
    68% {
        top: 0;
        height: 3rem;
    }
    71% {
        top: 3rem;
    }
    73.5% {
        top: -7rem;
        height: 15.5rem;
    }
    74.625% {
        top: -6.5rem;
    }
    75.75% {
        top: -6.75rem;
    }
    76.875% {
        top: -6.5rem;
    }
    88% {
        top: -6.5rem;
        height: 15.5rem;
    }
    90.5% {
        top: -.5rem;
        height: 3rem;
    }
    91.625% {
        top: 0;
    }
    92.75% {
        top: -.25rem;
    }
    93.875% {
        top: 0;
    }
}
@keyframes move3 {
    0% {
        top: 0;
        height: 3rem;
    }
    5.5% {
        top: -3rem; 
    }
    8% {
        top: .5rem;
    }
    9.125% {
        top: 0;
    }
    10.25% {
        top: .25rem;
    }
    11.375% {
        top: 0;
    }
    15% {
        top: 0;
        height: 3rem;
    }
    19% {
        top: 1rem;
        height: 8rem;
    }
    20.125% {
        top: .5rem;
        height: 8rem;
    }
    21.25% {
        top: .75rem;
    }
    22.375% {
        top: 1rem;
    }
    40% {
        top: 1rem;
        height: 8rem;
    }
    45.5% {
        top: -.5rem
    }
    46.625% {
        top: 0;
        height: 3rem;
    }
    47.75% {
        top: -.25rem;
    }
    48.875% {
        top: 0;
    }
    53% {
        top: 0;
    }
    58.5% {
        top: -3rem;
    }
    61% {
        top: .5rem;
    }
    62.125% {
        top: 0;
    }
    63.25% {
        top: .25rem;
    }
    64.375% {
        top: 0;
    }
    68% {
        top: 0;
        height: 3rem;
    }
    72.5% {
        top: 3rem;
    }
    75% {
        top: -3.5rem;
        height: 12rem;
    }
    76.125% {
        top: -3rem;
    }
    77.25% {
        top: -3.25rem;
    }
    78.375% {
        top: -3rem;
    }
    88% {
        top: -3rem;
        height: 12rem
    }
    92% {
        top: -.5rem;
        height: 3rem;
    }
    93.125% {
        top: 0;
    }
    94.25% {
        top: -.25rem;
    }
    95.375% {
        top: 0;
    }
}
@keyframes move4 {
    0% {
        top: 0;
        height: 3rem;
    }
    7% {
        top: -3rem; 
    }
    9.5% {
        top: .5rem;
    }
    10.625% {
        top: 0;
    }
    11.75% {
        top: .25rem;
    }
    12.875% {
        top: 0rem;
    }
    15% {
        top: 0;
        height: 3rem;
    }
    20.5% {
        top: -3rem;
        height: 12rem;
    }
    21.625% {
        top: -2.5rem;
        height: 12rem;
    }
    22.75% {
        top: -2.75rem;
    }
    23.875% {
        top: -3rem;
    }
    40% {
        top: -3rem;
        height: 12rem;
    }
    47% {
        top: -.5rem;
    }
    48.125% {
        top: 0;
        height: 3rem;
    }
    49.25% {
        top: -.25rem
    }
    50.375% {
        top: 0;
    }
    53% {
        top: 0;
    }
    60% {
        top: -3rem;
    }
    62.5% {
        top: .5rem;
    }
    63.625% {
        top: 0;
    }
    64.75% {
        top: .25rem;
    }
    65.875% {
        top: 0;
    }
    68% {
        top: 0;
        height: 3rem;
    }
    74% {
        top: 3rem;
    }
    76.5% {
        top: -2.5rem;
        height: 11rem;
    }
    77.625% {
        top: -2rem;
    }
    78.75% {
        top: -2.25rem;
    }
    79.875% {
        top: -2rem;
    }
    88% {
        top: -2rem;
        height: 11rem;
    }
    93.5% {
        top: -.5rem;
        height: 3rem;
    }
    94.625% {
        top: 0;
    }
    95.75% {
        top: -.25rem;
    }
    96.875% {
        top: 0;
    }
}
@keyframes move5 {
    0% {
        top: 0;
        height: 3rem;
    }
    8.5% {
        top: -3rem; 
    }
    11% {
        top: .5rem;
    }
    12.125% {
        top: 0;
    }
    13.25% {
        top: .25rem;
    }
    14.375% {
        top: 0rem;
    }
    15% {
        top: 0;
        height: 3rem;
    }
    22% {
        top: -7rem;
        height: 16rem;
    }
    23.125% {
        top: -6.5rem;
        height: 16rem;
    }
    24.25% {
        top: -6.75rem;
    }
    25.375% {
        top: -7rem;
    }
    40% {
        top: -7rem;
        height: 16rem;
    }
    48.5% {
        top: -.5rem;
    }
    49.625% {
        top: 0;
        height: 3rem;
    }
    50.75% {
        top: -.25rem;
    }
    51.875% {
        top: 0;
    }
    53% {
        top: 0;
    }
    61.5% {
        top: -3rem;
    }
    64% {
        top: .5rem;
    }
    65.125% {
        top: 0;
    }
    66.25% {
        top: .25rem;
    }
    67.375% {
        top: 0;
    }
    68% {
        top: 0;
        height: 3rem;  
    }
    75.5% {
        top: 3rem;
    }
    78% {
        top: -5.5rem;
        height: 14rem;
    }
    79.125% {
        top: -5rem;
    }
    80.25% {
        top: -5.25rem;
    }
    81.375% {
        top: -5rem;
    }
    88% {
        top: -5rem;
        height: 14rem;
    }
    95% {
        top: -.5rem;
        height: 3rem;
    }
    96.125% {
        top: 0;
    }
    97.25% {
        top: -.25rem;
    }
    98.375% {
        top: 0;
    }
}

@media screen and (max-width: 768px) {
    .lanalytics-container {
        height: 25rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .lanalytics-words {
        width: 40%;
    }
    .lanalytics-words p:first-of-type {
        font-size: 3.5rem;
    }
    .lanalytics-words p:last-of-type {
        font-size: 1.4rem;
    }
}
@media screen and (max-width: 650px) {
    .lanalytics-words p:first-of-type {
        font-size: 3rem;
    }
    .lanalytics-words p:last-of-type {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }
}
@media screen and (max-width: 550px) {
    .lanalytics-container {
        padding-left: .5rem;
        padding-right: .5rem;
    }
    .lanalytics-words p:first-of-type {
        font-size: 2.5rem;
    }
    .lanalytics-words p:last-of-type {
        font-size: 1.15rem;
    }
    .lanalytics-animate {
        width: 13.5rem;
    }
    .lanalytics-animate-inner {
        height: 2.5rem;
    }
    .animate1,
    .animate2,
    .animate3,
    .animate4,
    .animate5 {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 2.5rem;
    }
    .animate2 {
        left: 2.75rem;
    }
    .animate4 {
        right: 2.75rem;
    }
    @keyframes move1 {
        0% {
            top: 0;
            height: 2.5rem;
        }
        2.5% {
            top: -2.5rem; 
        }
        5% {
            top: .5rem;
        }
        6.125% {
            top: 0;
        }
        7.25% {
            top: .25rem;
        }
        8.375% {
            top: 0rem;
        }
        15% {
            top: 0;
        }
        16% {
            top: 6rem;
        }
        17.125% {
            top: 5.5rem;
        }
        18.25% {
            top: 5.75rem;
        }
        19.375% {
            top: 5.5rem;
        }
        40% {
            top: 5.5rem;
        }
        42.5% {
            top: -.5rem;
        }
        43.625% {
            top: 0;
        }
        44.75% {
            top: -.25rem;
        }
        45.875% {
            top: 0;
        }
        53% {
            top: 0;
        }
        55.5% {
            top: -2.5rem;
        }
        58% {
            top: .5rem;
        }
        59.125% {
            top: 0;
        }
        60.25% {
            top: .25rem;
        }
        61.375% {
            top: 0;
        }
        68% {
            top: 0;
            height: 2.5rem;
        }
        69.5% {
            top: 2.5rem;
        }
        71% {
            top: -.5rem;
            height: 8rem
        }
        72.125% {
            top: 0;
        }
        73.25% {
            top: -.25rem;
        }
        73.375% {
            top: 0;
        }
        88% {
            top: 0;
            height: 8rem;
        }
        89% {
            top: -.5rem;
            height: 2.5rem;
        }
        90.125% {
            top: 0;
        }
        91.25% {
            top: -.25rem;
        }
        92.375% {
            top: 0;
        }
    }
    @keyframes move2 {
        0% {
            top: 0;
            height: 2.5rem;
        }
        4% {
            top: -2.5rem; 
        }
        6.5% {
            top: .5rem;
        }
        7.625% {
            top: 0;
        }
        8.75% {
            top: .25rem;
        }
        9.875% {
            top: 0;
        }
        15% {
            top: 0;
            height: 2.5rem;
        }
        17.5% {
            top: 4rem;
            height: 4rem;
        }
        18.625% {
            top: 3.5rem;
            height: 4rem;
        }
        19.75% {
            top: 3.75rem;
        }
        20.875% {
            top: 4rem;
        }
        40% {
            top: 4rem;
            height: 4rem
        }
        44% {
            top: -.5rem;
        }
        45.125% {
            top: 0;
            height: 2.5rem;
        }
        46.25% {
            top: -.25rem;
        }
        47.375% {
            top: 0;
        }
        53%{
            top: 0;
        }
        57% {
            top: -2.5rem;
        }
        59.5% {
            top: .5rem;
        }
        60.625% {
            top: 0;
        }
        61.75% {
            top: .25rem;
        }
        62.875% {
            top: 0;
        }
        68% {
            top: 0;
            height: 2.5rem;
        }
        71% {
            top: 2.5rem;
        }
        73.5% {
            top: -6.5rem;
            height: 14rem;
        }
        74.625% {
            top: -6rem;
        }
        75.75% {
            top: -6.25rem;
        }
        76.875% {
            top: -6rem;
        }
        88% {
            top: -6rem;
            height: 14rem;
        }
        90.5% {
            top: -.5rem;
            height: 2.5rem;
        }
        91.625% {
            top: 0;
        }
        92.75% {
            top: -.25rem;
        }
        93.875% {
            top: 0;
        }
    }
    @keyframes move3 {
        0% {
            top: 0;
            height: 2.5rem;
        }
        5.5% {
            top: -2.5rem; 
        }
        8% {
            top: .5rem;
        }
        9.125% {
            top: 0;
        }
        10.25% {
            top: .25rem;
        }
        11.375% {
            top: 0;
        }
        15% {
            top: 0;
            height: 2.5rem
        }
        19% {
            top: .5rem;
            height: 7.5rem;
        }
        20.125% {
            top: 0;
            height: 7.5rem;
        }
        21.25% {
            top: .25rem;
        }
        22.375% {
            top: .5rem;
        }
        40% {
            top: .5rem;
            height: 7.5rem;
        }
        45.5% {
            top: -.5rem
        }
        46.625% {
            top: 0;
            height: 2.5rem;
        }
        47.75% {
            top: -.25rem;
        }
        48.875% {
            top: 0;
        }
        53% {
            top: 0;
        }
        58.5% {
            top: -2.5rem;
        }
        61% {
            top: .5rem;
        }
        62.125% {
            top: 0;
        }
        63.25% {
            top: .25rem;
        }
        64.375% {
            top: 0;
        }
        68% {
            top: 0;
            height: 2.5rem;
        }
        72.5% {
            top: 2.5rem;
        }
        75% {
            top: -3rem;
            height: 10.5rem;
        }
        76.125% {
            top: -2.5rem;
        }
        77.25% {
            top: -2.75rem;
        }
        78.375% {
            top: -2.5rem;
        }
        88% {
            top: -2.5rem;
            height: 10.5rem
        }
        92% {
            top: -.5rem;
            height: 2.5rem;
        }
        93.125% {
            top: 0;
        }
        94.25% {
            top: -.25rem;
        }
        95.375% {
            top: 0;
        }
    }
    @keyframes move4 {
        0% {
            top: 0;
            height: 2.5rem;
        }
        7% {
            top: -2.5rem; 
        }
        9.5% {
            top: .5rem;
        }
        10.625% {
            top: 0;
        }
        11.75% {
            top: .25rem;
        }
        12.875% {
            top: 0rem;
        }
        15% {
            top: 0;
            height: 2.5rem;
        }
        20.5% {
            top: -3rem;
            height: 11rem;
        }
        21.625% {
            top: -2.5rem;
            height: 11rem;
        }
        22.75% {
            top: -2.75rem;
        }
        23.875% {
            top: -3rem;
        }
        40% {
            top: -3rem;
            height: 11rem;
        }
        47% {
            top: -.5rem;
        }
        48.125% {
            top: 0;
            height: 2.5rem;
        }
        49.25% {
            top: -.25rem
        }
        50.375% {
            top: 0;
        }
        53% {
            top: 0;
        }
        60% {
            top: -2.5rem;
        }
        62.5% {
            top: .5rem;
        }
        63.625% {
            top: 0;
        }
        64.75% {
            top: .25rem;
        }
        65.875% {
            top: 0;
        }
        68% {
            top: 0;
            height: 2.5rem;
        }
        74% {
            top: 2.5rem;
        }
        76.5% {
            top: -2.5rem;
            height: 9.5rem;
        }
        77.625% {
            top: -1.5rem;
        }
        78.75% {
            top: -1.75rem;
        }
        79.875% {
            top: -1.5rem;
        }
        88% {
            top: -1.5rem;
            height: 9.5rem;
        }
        93.5% {
            top: -.5rem;
            height: 2.5rem;
        }
        94.625% {
            top: 0;
        }
        95.75% {
            top: -.25rem;
        }
        96.875% {
            top: 0;
        }
    }
    @keyframes move5 {
        0% {
            top: 0;
            height: 2.5rem;
        }
        8.5% {
            top: -2.5rem; 
        }
        11% {
            top: .5rem;
        }
        12.125% {
            top: 0;
        }
        13.25% {
            top: .25rem;
        }
        14.375% {
            top: 0rem;
        }
        15% {
            top: 0;
            height: 2.5rem;
        }
        22% {
            top: -7rem;
            height: 15rem;
        }
        23.125% {
            top: -6.5rem;
            height: 15rem;
        }
        24.25% {
            top: -6.75rem;
        }
        25.375% {
            top: -7rem;
        }
        40% {
            top: -7rem;
            height: 15rem;
        }
        48.5% {
            top: -.5rem;
        }
        49.625% {
            top: 0;
            height: 2.5rem;
        }
        50.75% {
            top: -.25rem;
        }
        51.875% {
            top: 0;
        }
        53% {
            top: 0;
        }
        61.5% {
            top: -2.5rem;
        }
        64% {
            top: .5rem;
        }
        65.125% {
            top: 0;
        }
        66.25% {
            top: .25rem;
        }
        67.375% {
            top: 0;
        }
        68% {
            top: 0;
            height: 2.5rem;  
        }
        75.5% {
            top: 2.5rem;
        }
        78% {
            top: -6.5rem;
            height: 14rem;
        }
        79.125% {
            top: -6rem;
        }
        80.25% {
            top: -6.25rem;
        }
        81.375% {
            top: -6rem;
        }
        88% {
            top: -6rem;
            height: 14rem;
        }
        95% {
            top: -.5rem;
            height: 2.5rem;
        }
        96.125% {
            top: 0;
        }
        97.25% {
            top: -.25rem;
        }
        98.375% {
            top: 0;
        }
    }
}
@media screen and (max-width: 480px) {
    .lanalytics-container-inner {
        justify-content: space-between;
    }
    .lanalytics-words {
        width: 35%;
    }
    .lanalytics-words p:first-of-type {
        font-size: 1.75rem;
    }
    .lanalytics-words p:last-of-type {
        font-size: 1rem;
        line-height: 1.4rem;
    }
}
@media screen and (max-width: 400px) {
    .lanalytics-container {
        padding-top: 1.5rem;
        height: 21rem;
    }
    .lanalytics-words {
        width: 40%;
    }
    .lanalytics-animate {
        width: 11rem;
    }
    .lanalytics-animate-inner {
        height: 2rem;
    }
    .animate1,
    .animate2,
    .animate3,
    .animate4,
    .animate5 {
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
    }
    .animate2 {
        left: 2.25rem;
    }
    .animate4 {
        right: 2.25rem;
    }
    @keyframes move1 {
        0% {
            top: 0;
            height: 2rem;
        }
        2.5% {
            top: -2rem; 
        }
        5% {
            top: .5rem;
        }
        6.125% {
            top: 0;
        }
        7.25% {
            top: .25rem;
        }
        8.375% {
            top: 0rem;
        }
        15% {
            top: 0;
        }
        16% {
            top: 5rem;
        }
        17.125% {
            top: 4.5rem;
        }
        18.25% {
            top: 4.75rem;
        }
        19.375% {
            top: 4.5rem;
        }
        40% {
            top: 4.5rem;
        }
        42.5% {
            top: -.5rem;
        }
        43.625% {
            top: 0;
        }
        44.75% {
            top: -.25rem;
        }
        45.875% {
            top: 0;
        }
        53% {
            top: 0;
        }
        55.5% {
            top: -2rem;
        }
        58% {
            top: .5rem;
        }
        59.125% {
            top: 0;
        }
        60.25% {
            top: .25rem;
        }
        61.375% {
            top: 0;
        }
        68% {
            top: 0;
            height: 2rem;
        }
        69.5% {
            top: 2rem;
        }
        71% {
            top: -.5rem;
            height: 6.5rem
        }
        72.125% {
            top: 0;
        }
        73.25% {
            top: -.25rem;
        }
        73.375% {
            top: 0;
        }
        88% {
            top: 0;
            height: 6.5rem;
        }
        89% {
            top: -.5rem;
            height: 2rem;
        }
        90.125% {
            top: 0;
        }
        91.25% {
            top: -.25rem;
        }
        92.375% {
            top: 0;
        }
    }
    @keyframes move2 {
        0% {
            top: 0;
            height: 2rem;
        }
        4% {
            top: -2rem; 
        }
        6.5% {
            top: .5rem;
        }
        7.625% {
            top: 0;
        }
        8.75% {
            top: .25rem;
        }
        9.875% {
            top: 0;
        }
        15% {
            top: 0;
            height: 2rem;
        }
        17.5% {
            top: 3.5rem;
            height: 3rem;
        }
        18.625% {
            top: 3rem;
            height: 3rem;
        }
        19.75% {
            top: 3.25rem;
        }
        20.875% {
            top: 3.5rem;
        }
        40% {
            top: 3.5rem;
            height: 3rem
        }
        44% {
            top: -.5rem;
        }
        45.125% {
            top: 0;
            height: 2rem;
        }
        46.25% {
            top: -.25rem;
        }
        47.375% {
            top: 0;
        }
        53%{
            top: 0;
        }
        57% {
            top: -2rem;
        }
        59.5% {
            top: .5rem;
        }
        60.625% {
            top: 0;
        }
        61.75% {
            top: .25rem;
        }
        62.875% {
            top: 0;
        }
        68% {
            top: 0;
            height: 2rem;
        }
        71% {
            top: 2rem;
        }
        73.5% {
            top: -5rem;
            height: 11rem;
        }
        74.625% {
            top: -4.5rem;
        }
        75.75% {
            top: -4.75rem;
        }
        76.875% {
            top: -4.5rem;
        }
        88% {
            top: -4.5rem;
            height: 11rem;
        }
        90.5% {
            top: -.5rem;
            height: 2rem;
        }
        91.625% {
            top: 0;
        }
        92.75% {
            top: -.25rem;
        }
        93.875% {
            top: 0;
        }
    }
    @keyframes move3 {
        0% {
            top: 0;
            height: 2rem;
        }
        5.5% {
            top: -2rem; 
        }
        8% {
            top: .5rem;
        }
        9.125% {
            top: 0;
        }
        10.25% {
            top: .25rem;
        }
        11.375% {
            top: 0;
        }
        15% {
            top: 0;
            height: 2rem
        }
        19% {
            top: 1rem;
            height: 5.5rem;
        }
        20.125% {
            top: .5;
            height: 5.5rem;
        }
        21.25% {
            top: .75rem;
        }
        22.375% {
            top: 1rem;
        }
        40% {
            top: 1rem;
            height: 5.5rem;
        }
        45.5% {
            top: -.5rem
        }
        46.625% {
            top: 0;
            height: 2rem;
        }
        47.75% {
            top: -.25rem;
        }
        48.875% {
            top: 0;
        }
        53% {
            top: 0;
        }
        58.5% {
            top: -2rem;
        }
        61% {
            top: .5rem;
        }
        62.125% {
            top: 0;
        }
        63.25% {
            top: .25rem;
        }
        64.375% {
            top: 0;
        }
        68% {
            top: 0;
            height: 2rem;
        }
        72.5% {
            top: 2rem;
        }
        75% {
            top: -2rem;
            height: 8rem;
        }
        76.125% {
            top: -1.5rem;
        }
        77.25% {
            top: -1.75rem;
        }
        78.375% {
            top: -1.5rem;
        }
        88% {
            top: -1.5rem;
            height: 8rem
        }
        92% {
            top: -.5rem;
            height: 2rem;
        }
        93.125% {
            top: 0;
        }
        94.25% {
            top: -.25rem;
        }
        95.375% {
            top: 0;
        }
    }
    @keyframes move4 {
        0% {
            top: 0;
            height: 2rem;
        }
        7% {
            top: -2rem; 
        }
        9.5% {
            top: .5rem;
        }
        10.625% {
            top: 0;
        }
        11.75% {
            top: .25rem;
        }
        12.875% {
            top: 0rem;
        }
        15% {
            top: 0;
            height: 2rem;
        }
        20.5% {
            top: -2rem;
            height: 8.5rem;
        }
        21.625% {
            top: -1.5rem;
            height: 8.5rem;
        }
        22.75% {
            top: -1.75rem;
        }
        23.875% {
            top: -2rem;
        }
        40% {
            top: -2rem;
            height: 8.5rem;
        }
        47% {
            top: -.5rem;
        }
        48.125% {
            top: 0;
            height: 2rem;
        }
        49.25% {
            top: -.25rem
        }
        50.375% {
            top: 0;
        }
        53% {
            top: 0;
        }
        60% {
            top: -2rem;
        }
        62.5% {
            top: .5rem;
        }
        63.625% {
            top: 0;
        }
        64.75% {
            top: .25rem;
        }
        65.875% {
            top: 0;
        }
        68% {
            top: 0;
            height: 2rem;
        }
        74% {
            top: 2rem;
        }
        76.5% {
            top: -1.5rem;
            height: 7rem;
        }
        77.625% {
            top: -.5rem;
        }
        78.75% {
            top: -.75rem;
        }
        79.875% {
            top: -.5rem;
        }
        88% {
            top: -.5rem;
            height: 7rem;
        }
        93.5% {
            top: -.5rem;
            height: 2rem;
        }
        94.625% {
            top: 0;
        }
        95.75% {
            top: -.25rem;
        }
        96.875% {
            top: 0;
        }
    }
    @keyframes move5 {
        0% {
            top: 0;
            height: 2rem;
        }
        8.5% {
            top: -2rem; 
        }
        11% {
            top: .5rem;
        }
        12.125% {
            top: 0;
        }
        13.25% {
            top: .25rem;
        }
        14.375% {
            top: 0rem;
        }
        15% {
            top: 0;
            height: 2rem;
        }
        22% {
            top: -5.5rem;
            height: 12rem;
        }
        23.125% {
            top: -5rem;
            height: 12rem;
        }
        24.25% {
            top: -5.25rem;
        }
        25.375% {
            top: -5.5rem;
        }
        40% {
            top: -5.5rem;
            height: 12rem;
        }
        48.5% {
            top: -.5rem;
        }
        49.625% {
            top: 0;
            height: 2rem;
        }
        50.75% {
            top: -.25rem;
        }
        51.875% {
            top: 0;
        }
        53% {
            top: 0;
        }
        61.5% {
            top: -2rem;
        }
        64% {
            top: .5rem;
        }
        65.125% {
            top: 0;
        }
        66.25% {
            top: .25rem;
        }
        67.375% {
            top: 0;
        }
        68% {
            top: 0;
            height: 2rem;  
        }
        75.5% {
            top: 2rem;
        }
        78% {
            top: -4.5rem;
            height: 9.5rem;
        }
        79.125% {
            top: -3rem;
        }
        80.25% {
            top: -3.25rem;
        }
        81.375% {
            top: -3rem;
        }
        88% {
            top: -3rem;
            height: 9.5rem;
        }
        95% {
            top: -.5rem;
            height: 2rem;
        }
        96.125% {
            top: 0;
        }
        97.25% {
            top: -.25rem;
        }
        98.375% {
            top: 0;
        }
    }
}
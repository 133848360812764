.ready-set-container {
    width: 100%;
    height: 35rem;
    background-color: #607aa4;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ready-set-container p:first-of-type {
    z-index: 6;
    font-size: 6rem;
    font-weight: bold;
    color: #e7e7e3;
}
.ready-set-container p:last-of-type {
    z-index: 6;
    font-size: 1.75rem;
    color: #e7e7e3;
}
.ready-set-input-wrapper {
    width: 100%;
    max-width: 50rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
    position: relative;
    padding: 0 .5rem;
}
.ready-set-input,
.ready-set-input:is(:hover,:focus,:active) {
    width: 100%;
    max-width: 50rem;
    height: 4rem;
    border: 3px solid #607aa4;
    border-radius: 4rem !important;
    background: rgba(0,0,0,.5);
    padding-top: .5rem;
    padding-left: 1.75rem;
    color: #e7e7e3;
    font-size: 1.5rem;
    outline: none;
}
.ready-set-container input:focus + .floating-label {
    top: .2rem;
    left: 2.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}
.ready-set-container .floating-label {
    position: absolute;
    pointer-events: none;
    left: 2rem;
    top: .55rem;
    font-size: 1.75rem;
    transition: 0.2s ease all;
    color: #e7e7e3;
    opacity: 0.8;
}
.ready-set-container .floating-label.filled {
    top: .2rem;
    left: 2.5rem;
    font-size: 11px;
    text-transform: uppercase;
    opacity: 1;
}

.ready-set-btn {
    position: absolute;
    right: 1.25rem;
    color: #e7e7e3;
    height: 2.5rem;
    width: 6rem;
    border: none;
    border-radius: 2.5rem !important;
    background: linear-gradient(to right top, #161d2d, #607aa4) !important;
}

@media screen and (max-width: 768px) {
    .ready-set-container p:first-of-type {
        font-size: 4rem;
    }
    .ready-set-container p:last-of-type {
        font-size: 1.5rem;
        padding: 0 .5rem;
        text-align: center;
    }
}
@media screen and (max-width: 480px) {
    .ready-set-container {
        height: 30rem;
    }
    .ready-set-container p:first-of-type {
        font-size: 2.75rem;
    }
    .ready-set-container p:last-of-type {
        font-size: 1.25rem;
        margin-bottom: .5rem;
    }
    .ready-set-input,
    .ready-set-input:is(:hover,:focus,:active) {
        height: 3.5rem;
        font-size: 1rem;
    }
    .ready-set-container .floating-label {
        top: .5rem;
        font-size: 1.5rem;
    }
    .ready-set-btn {
        height: 2rem;
    }
}

.landing-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}
.landing-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ripple-container {
    width: 100%;
    height: 40rem;
    /* background-color: #e9eaef; */
    background-color: #161d2d;
    z-index: 2;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.ripple-container p:first-of-type {
    text-align: center;
    position: absolute;
    width: 100%;
    top: 58.5%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.75rem;
    font-weight: bold;
    z-index: 5;
    color: #e7e7e3;
    line-height: 4.5rem;
    margin-bottom: 0;
}
.ripple-container p:last-of-type {
    text-align: center;
    position: absolute;
    width: 36rem;
    top: 82%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    line-height: 1.9rem;
    color: #e7e7e3;
    z-index: 4;
}
.ripple-words-wrap {
    position: absolute;
    top: 66%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 2.5rem;
    height: 4rem;
    width: 100%;
    z-index: 5;
    color: #e7e7e3;
    display: flex;
    justify-content: center;
    animation: fadein 3s linear infinite;
}
.ripple-img {
    z-index: 10;
    width: 20rem;
    height: auto;
    position: absolute;
    top: 22%;
    left: calc(50% - 10.25rem);
}
@keyframes fadein {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    95% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
.ripple-btns {
    position: absolute;
    top: 92%;
    width: 100%;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.start-free-btn,
.start-free-btn:is(:hover,:focus,:active),
.compare-plans-btn,
.compare-plans-btn:is(:hover,:focus,:active) {
    width: 12rem;
    height: 3rem;
    background-color: #e7e7e3;
    color: #000;
    border: none;
    border-radius: 3rem;
    font-size: 1.2rem;
    z-index: 5;
}

.water-container {
    width: 100%;
    height: 14rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-size: 100% 15rem !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    color: #e7e7e3;
    padding: 0 1rem;
    text-align: center;
    position: relative;
}
.water-container-top,
.water-container-bottom {
    width: 100%;
    height: 2rem;
    /* background-color: #e9eaef; */
    background-color: #161d2d;
    position: absolute;
    z-index: 2;
}
.water-container-top {
    border-radius: 0 0 20rem 20rem;
    box-shadow: 8px 8px 1rem rgba(0,0,0,.4),
                -8px 0 1rem rgba(0,0,0,.4);
    top: 0;
}
.water-container-bottom {
    border-radius: 20rem 20rem 0 0;
    box-shadow: 8px -8px 1rem rgba(0,0,0,.4),
                -8px 0 1rem rgba(0,0,0,.4);
    bottom: 0;
}
.water-container p:first-of-type {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 3.5rem;
    color: #e7e7e3;
}

@media screen and (max-width: 768px) {
    .ripple-img {
        width: 20rem;
        top: 22%;
        left: calc(50% - 10rem);
    }
    .ripple-container p:last-of-type {
        max-width: 100%;
        padding: 0 1rem;
        top: 84%;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }
    .ripple-btns {
        top: 93%;
    }
    .start-free-btn,
    .start-free-btn:is(:hover,:focus,:active),
    .compare-plans-btn,
    .compare-plans-btn:is(:hover,:focus,:active) {
        height: 2.5rem;
        width: 11rem;
    }
    .water-container {
        background-size: 100% 35rem !important;
    }
    .water-container p:first-of-type {
        font-size: 2.75rem;
        line-height: 2.6rem;
    }
}
@media screen and (max-width: 480px) {
    .water-container {
        background-size: 100% 25rem !important;
    }
    .ripple-container p:last-of-type {
        max-width: 100%;
        padding: 0 1rem;
        top: 84%;
        font-size: 1.25rem;
        line-height: 1.7rem;
    }
    .water-container p:first-of-type {
        font-size: 2rem;
    }   
    .start-free-btn,
    .start-free-btn:is(:hover,:focus,:active),
    .compare-plans-btn,
    .compare-plans-btn:is(:hover,:focus,:active) {
        height: 2.5rem;
        width: 9.5rem;
        font-size: 1rem;
    }
}

